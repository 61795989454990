.headingdiv{background: #EFEFEF;
border-radius: 12px 12px 0px 0px;
padding: 10px 10px;
    margin-bottom: -5px;
}
.heading {
font-weight: 600!important;
font-size: 16px!important;
line-height: 19px!important;
color: #162133;
}
.heading h4 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #91959e;
  margin-bottom: 20px;
}
.Outerbox
{
background: #FFFFFF;
border: 1px solid #FFFFFF;
box-shadow: 0px 8px 24px rgba(139, 154, 169, 0.25);
border-radius: 12px;
}
.tabs{
	border-bottom: 1px solid;
    border-color: #EAEDFE;
}
.tabs button{
text-transform: capitalize;
}
.heading h5 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #162133;
  margin-bottom: 20px;
  text-transform: capitalize;
}
.planTabs button {
  justify-content: flex-start;
  min-height: auto;
  margin: 15px 10px;
  padding: 0px 5px;
  border-radius: 0px;
}
.planTabs h6 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #91959e;
  margin-left: 10px;
}
.planTabs .active {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #162133;
  border-left: 3px solid #faaf56;
}
.planTabs .active h6 {
  color: #162133;
  font-weight: 500;
}

.planRow{
border-bottom: 1px solid;
    border-color: #EAEDFE;
margin-bottom:20px;
}

.planRow h5{
font-weight: 500;
font-size: 12px;
line-height: 18px;
color: #34373E;
margin:0
}

.planRow p{
font-weight: 400;
font-size: 12px;
line-height: 14px;
color: #2341D5;
    margin: 5px 0 10px 0;
}

.planRow span{
font-weight: 400;
font-size: 10px;
line-height: 12px;
color: #91959E;
}

.planRow button{
background: #FFFFFF;
border: 0.5px solid #2442D5;
border-radius: 5px;
color:#2442D5;
}

.planRow button:hover{
color: #FFFFFF;
background:#2442D5;
}