.footerlogo {
   max-width: 120px;
 }
 .links{
   margin-bottom: 10px;margin-top:20px;
 }
 .links h6{
	 display:inline-block;   margin:0 25px 0 0;
 }
.links a{
   font-family: 'Roboto';
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   line-height: 20px;
   text-transform: capitalize;
   color: #2D3144;
margin-right: 30px;
   text-decoration:none;
   padding: 15px 0px;
}
.links a:hover{
color: #2341D5;cursor: pointer;
}


.box {
   background: #FFFFFF;
   box-shadow: 0px 7px 50px rgba(0, 0, 0, 0.05);
   border-radius: 15px;
   padding: 20px 20px;
   flex-wrap: nowrap;
   cursor: pointer;
 }
 .box:hover {
   background: #2341D5;
 }

 .box:hover h5{
    color:#fff;
 }

 .box:hover h6{
  color: #B1C9FF
 }

 .box h5 {
   font-family: 'Roboto';
   font-style: normal;
   font-weight: 600;
   font-size: 14px;
   line-height: 16px;
   color:#2D3144;
   margin-bottom:6px ;
 }
 
 .box h6 {
   font-family: 'Roboto';
   font-style: normal;
   font-weight: 500;
   font-size: 12px;
   line-height: 14px;
   color:#2D3144;
 }

 .box img{
    margin-right: 15px;

 }
 .modulelinks{
margin-bottom:"5rem"
 }
 .modulelinks h3{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  position: relative;
      border-bottom: 0.5px solid #c9cfdb;
    padding-bottom: 20px;
 }


 .footerbottom{
  background: #2D3144;
  border-radius: 10px 10px 0px 0px;
   display: flex;
   justify-content: space-between;
   padding: 0px 20px;
   margin-top: 2rem;
 }

 .footerbottom a{
   color:#fff;
padding: 1rem 10px;
   margin:15px 5px;
   cursor:pointer;
   text-decoration:none;
}
.footerbottom a:hover{
  text-decoration:none;cursor: pointer;
}
.bottomlinks{
  padding: 15px 0px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  color: #2D3144;
  margin-right:25px;
}

.bottomlinks:hover{
  color:#fff;
}
.footerbottom p{
  color:#fff;font-size: 14px;margin: 15px 0;
}
.footermobilebottom p{
font-size:12px;
}
@media (max-width:767px) {
  .links h6{
    display: block;
    padding: 0.5rem 0rem;
    margin: 5px;
	font-weight: 300;
font-size: 14px;
line-height: 16px;
color: #2D3144;
  }
  .footerTop h6{
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 20px;
margin-top:15px;
  }
  .footerbottom{
    text-align:center;

  }
  .footerbottom a{
    display: inline-block;
    padding: 0;
    margin: 5px;
    text-align: center;
    justify-content: center;
    border-right: 1px solid #fff;
    padding-right: 10px;
  }
  .footerbottom a:last-child{
    border-right: 0px solid #fff;
  }
  .modulelinks{
    margin-top:35px!important;
  }
   .modulelinks h5{
	   color:#656876;
font-weight: 500;
font-size: 14px;
line-height: 16px;
   }
   

  .modulelinks h3[aria-expanded=true]:after {
    -webkit-transition: all .4s;
    transition: all .4s;
    top: 26px;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
}
  .modulelinks h3:after{
  border-bottom: 2px solid #6c6f7e;
    border-right: 2px solid #6c6f7e;
    content: "";
    display: block;
    height: 10px;
    left: auto;
    right: 15px;
    position: absolute;
    top: 0px;
    width: 10px;
    -webkit-transition: all .4s;
    transition: all .4s;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}