.MuiList-root{padding:10px 12px!important;
}

.MuiMenu-paper
{
	box-shadow: 0px 7px 25px rgba(117, 128, 137, 0.25)!important;
border-radius: 5px!important;
}
.MuiPaper-root::-webkit-scrollbar{width:3px;background:#F5F5F5}
.MuiPaper-root::-webkit-scrollbar-thumb{background: #2341D5;border-radius: 2px;} 
li.MuiMenuItem-root,li.Mui-selected{ border-radius: 10px!important;padding:10px 10px!important;font-size:16px!important;font-weight: 400;}
.MuiFilledInput-input:focus {
    background-color: #fff!important;
}

.MuiTab-root.Mui-selected{
   box-shadow: none!important;
}
.MuiTab-root.Mui-selected h2, .MuiTab-root.Mui-selected span{
     color: #162133;
    font-weight: 600;
}
.MuiTab-root.Mui-selected img{
filter:brightness(0);
}
.MuiMenuItem-root{
	font-weight:500
}
.MuiMenuItem-root:hover{
	background-color: #2341D5!important;
    color: #fff;
}

.MuiMenuItem-root:hover img{
	filter: brightness(2);
}
/*
.MuiTabs-scroller{
	overflow-y:visible!important;
}
*/
.plansPage .Mui-selected h6 {
  color: #162133;
  font-weight: 500;
}

.plansPage .Mui-selected img{
filter: brightness(0);
}


.selectLabel.MuiInputLabel-shrink
{
	transform: translate(12px, 12px) scale(1)!important;
}
.selectLabel{
		font-size: 16px!important;
	color:rgba(0, 0, 0, 0.6)!important;
	transform: translate(12px, 30px) scale(1)!important;
}

.MuiTabs-root{
	overflow-x:auto!important;
}