.main-banner{
	margin-bottom: -250px;
    height: 400px;
    overflow: hidden;
}

.main-banner .slick-slide {
   width: 100%;
text-align: center;
  box-sizing: border-box;

}

.main-banner .slick-slide img {
  margin: 0 auto;
  max-width: 100%;
}

.main-banner .slick-next,
.main-banner .slick-prev {
  width: 35px;
  height: 35px;
  z-index: 9;
  top: 20%;
  transform: translateY(-50%);
  background: #FFE3AF;
box-shadow: 0px 4px 10px rgba(225, 173, 81, 0.5);
    border-radius: 100%;
	 background-repeat: no-repeat;
    background-position: center;
}

.main-banner .slick-prev,.main-banner .slick-prev:hover {
      background-image: url('../../../public/assets/images/Icons/left-arrow.svg');
   
}
.main-banner .slick-next,.main-banner .slick-next:hover {
    background-image: url('../../../public/assets/images/Icons/right-arrow.svg');
   
}

.main-banner .slick-prev:before, .main-banner .slick-next:before{
    content: "";
}
/*
.main-banner .slick-next:hover,
.main-banner .slick-prev:hover {
  background: #FFF;
}
*/
.main-banner .slick-prev {
   left: 2%;
}
.main-banner .slick-next {
   right: 2%;
}
@media(min-width:1600px){
.main-banner .slick-prev {
   left: 11%;
}
.main-banner .slick-next {
   right: 11%;
}
}

@media(max-width:767px){
.main-banner .slick-next, .main-banner .slick-prev{
	display:none!important;
}
.main-banner {
  border-radius: 25px;
}
}