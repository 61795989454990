.box {
  max-width: 480px;
  border: 1px solid #2341d5;
  border-radius: 20px !important;
  position: relative;
  margin: auto;
  align-items: center;
  padding: 20px;
}

.box:hover {
  background: #edefff;
}
.box h5, .box h6{
	z-index:9
}
.box h3 {
  position: absolute;
  right: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 150px;
  line-height: 0px;
  bottom: 30px;
  color: #edefff;
  margin-bottom: 20px;
}
.box:hover h3 {
  color: #fff;
}
.boxDesc{
  max-width: 85%;
}
.grid {
  padding-bottom: 0px;
}

.grid img {
  width: 100px;
}

.grid:nth-child(1) {
  background: url("../../../public/assets/images/homepage/down-arrow.svg");
  background-repeat: no-repeat;
  background-position: bottom 0px right -15px;
  padding-bottom: 30px;
}


.grid:last-child {
  background: url("../../../public/assets/images/homepage/up-arrow.svg");
  background-repeat: no-repeat;
  background-position: top 0px right 0px;
  padding-top: 30px;
}

.headingdiv {
  margin-bottom: 30px;
}
.headingdiv .heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  text-transform: capitalize;
  color: #162133;
}

.headingdiv .sub {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #91959e;
  margin-bottom: 30px;
}
@media (max-width: 1439px) {

  .box {
  max-width: 455px;
  }
}
@media (max-width: 1279px) {
	 .box {
  max-width: 400px;
  }
    .grid:nth-child(1),.grid:last-child {
    background:none;
  }
    .grid h5 {
    font-size: 18px;
    font-weight: bold;
  }
   .grid h6 {
    font-size: 14px;
    font-weight: 500;
  }
  .grid h3 {
    display: none;
  }
}
@media (max-width: 1023px) {

 	 .box {
  max-width: 360px;
  }
    .grid h5 {
    font-size: 17px;
    font-weight: bold;
  }
   .grid h6 {
    font-size: 12px;
    font-weight: 500;
  }
  .grid h3 {
    display: none;
  }

}
@media (max-width: 767px) {
   .headingdiv{
      margin-bottom: 0;
   }
  .headingdiv .heading {
    font-size: 18px;
    line-height: 0px;
    text-align: left;
	margin-left:15px!important;
  }
  .headingdiv .sub {
    font-size: 14px;
    line-height: 18px;
    text-align: left;
	margin-left:15px!important;
	margin-bottom:15px;
  }
  .box {
    padding: 10px;border-radius:10px!important;margin: 10px ;
  }
  .grid h5 {
    font-size: 16px;
    font-weight: bold;
  }
  .grid h6 {
    font-size: 12px;
    font-weight: 500;
  }
  .grid h3 {
    display: none;
  }
  .grid:nth-child(1),.grid:last-child {
    background:none;
  }
  .grid:nth-child(1) {
  padding-bottom: 0px;
}
}
