.MuiAccordion-root{
margin:20px 0;
border: 1px solid #EAEDFF!important;
border-radius: 6px;
box-shadow:none!important;
padding: 15px 5px;
}
.MuiAccordion-root.Mui-expanded{
background: #F5F6FF;
border: 1px solid #EAEDFF;
border-radius: 6px;
}
.MuiAccordion-root:before {
height: 0px!important;
}
.MuiAccordion-root h4{
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.0015em;
color: #162133;
margin:0
}

.MuiAccordion-root p{
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.0015em;
color: #162133;
margin-left: 18px;
}
.MuiAccordionSummary-content{
padding-right: 50px;
}
.MuiAccordionSummary-content:after{
    float: right;
    position: absolute;
    right: 30px;
    top: 15px;
	font-family: fontawesome;
    content:url('../../../public/assets/images/Icons/plus.svg');
}

.MuiAccordionSummary-content.Mui-expanded:after{
	   content:url('../../../public/assets/images/Icons/minus.svg');
}
