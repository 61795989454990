.card
{background: #fff;
border-radius: 10px;
text-align: center;
box-shadow: none!important;
cursor: pointer;
}
.card .MuiCardContent-root{
	background:#fff;
}
.card:hover
{
box-shadow: 0px 19px 50px #D8DBF8!important;
}
.headingdiv{
   margin-bottom: 30px;
}
.headingdiv .heading
{
font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 35px;
text-align: center;
color: #162133;
}

.headingdiv .sub{
   
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
text-align: center;

color: #91959E;
margin-bottom: 30px;
}
.card h5{
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */

letter-spacing: 0.15px;
}

.card p{
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
text-align: center;
letter-spacing: 0.4px;
color: #000000;
min-height: 30px
}

@media(max-width:767px){
	.headingdiv{
		margin-left: 5px!important;
	}
   .headingdiv .heading{
   font-size: 18px;
   line-height: 18px;
   text-align: left;
   }
   .headingdiv .sub{
      font-size: 14px;
      line-height: 18px;
      text-align: left;
	  margin-bottom:15px;
      }
}