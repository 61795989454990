.box{
background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
border-radius: 6px;
margin-right: 20px!important;
align-self: center;
cursor:pointer;
padding:20px 20px 10px 20px;
}

.boxshadow
{
border-radius: 6px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
    margin-right: 15px!important;
    width: max-content;
    padding: 10px 10px;
    display: inline-block;
    margin-bottom: 20px!important;
	font-weight: 500;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.0015em;
color: #162133;
}

.boxshadow:hover{
background: #F5F6FF;
color:#162133;
	font-weight: 600;
}

@media(max-width:767px){
	.box{
		padding:10px 10px 10px 10px!important;
	}
	.boxshadow
{
	    padding: 5px 10px;
		font-size: 12px;
}
}

