.profileImgBox img{
  max-width: fit-content;
}

.profileImgBox{
  text-align: center;
  margin:20px auto;
  display: block;
}

.profileBox .updateImg:hover{
  background: #2b6ede;
}

.profileBox .updateImg{
    background: #faba3f;
    width: 30px;
    height: 30px;
    padding: 0;
    min-width: 30px;
    border-radius: 50%;
    top: -30px;
    left: 40px;
    border: 2px solid #fff;
}

.profileBox .sideMenu .menuList a{
  padding: 2px 20px;
  color: #586cd8;
  border-radius: 10px;
  background: #fff;
}
.profileBox .sideMenu .menuList a:hover,
.profileBox .sideMenu .menuList a.active{
    background: #586cd8;
    color: #fff;
}
.profileBox .sideMenu .menuList a:hover .sideIcon img,
.profileBox .sideMenu .menuList a.active .sideIcon img{
  filter: brightness(10);
}
.completeProfile h6{
  margin-top: 22px;
  font-size: 14px;
  color: #222;
  font-weight: 500;
  line-height: 18px;
}
.completeProfile .progressSet{
  width: 100px!important;
  height: 90px!important;
}
.completeProfile svg{
  height: 80px!important;
  width: auto;
}
.completeProfile .indicator{
  position: absolute;
  margin: auto;
  width: 100%;
  margin-top: -58px;
}
.profileBox .sideMenu .menuList a.active small {
    background: #586cd8;
    width: 10px;
    margin-left: -70px;
    position: absolute;
    border-radius: 10px;
    height: 100%;
}
.profileBox .sideMenu .menuList a .sideIcon img{
  height: 30px;
  width: 30px;
}
.profileBox .sideMenu .menuList a .sideIcon{
  min-width: 50px;
}
.profileBox .sideMenu .menuList a .menuTitle span{
  font-weight: 700;
  font-size: 18px;
}
.profileBox .sideMenu .menuList a.active .menuTitle span,
.profileBox .sideMenu .menuList a:hover .menuTitle span{
  font-weight: 500;
}

.helpBox{
  background:#ede9fe;
  padding:15px 15px;
  border-radius:12px;
  margin:20px;
  text-align:center;
}
.helpBox .helpText{
  display: flex;
  width: fit-content;
  margin: auto;
  padding: 5px;
}
.helpBox .chatImg{
  margin-right: 8px;
}
.helpBox .helpText h4{
  color: #111;
}
.helpBox h6{
  font-size: 14px;
}
.helpBox button:hover{
  background: #faba3f;
}
.helpBox button{
  font-size: 18px;
  display: block;
  width: 100%;
  padding: 8px 15px;
  background: #586cd8;
  border-radius: 8px;
  top: 0;
  right: 0;
  left: 0;
  height: auto;
  color: #fff;
  margin-top: 6px;
  text-transform: capitalize;
}

.profileImg{
  height: 100px;
  width: 100px;
  margin: auto;
  border-radius: 50%;
  box-shadow: 0px 0px 4px -1px #777;
  display: block;
}

.profileImgBox h4{
  font-size: 24px;
  font-weight: 500;
  display: block;
  color: #586cd8;
}

.profileImgBox h5{
  font-size: 14px;
  font-weight: 400;
  display: block;
}


.profileDetails{
  padding: 20px;
}

.profileDataList .verified,
.profileDataList .nonVerified{
  position: absolute;
  width: 130px;
  right: 0;
}
.profileDataList .verified svg,
.profileDataList .nonVerified svg{
  width: 16px;
  height: 18px;
  vertical-align: bottom;
}

.profileDataList .nonVerified{
  font-size: 12px;
  color: #df3b38;
}

.profileDataList .verified{
  font-size: 12px;
  color: #55ce3c;
}

.profileDetails .actionBtn{
  width: 80px;
  display: initial;
}

.profileDetails h3{
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 10px;
}

.profileDetails button{
    float: right;
    padding: 0;
    margin: 0 2px;
    min-width: auto;
    background: none;
    box-shadow: none;
    width: max-content;
}

.profileDetails button:hover{
  background: #586cd8;
  border-radius: 4px;
}

.profileDetails button:hover img{
  filter: brightness(2);
}
.profileDetails button .profileEdit{
  height: 26px;
  margin: 2px 2px;
}

.profileDetails .profileDataList li{
  padding: 0;
  margin: 0;
}
.profileDetails .profileDataList li div{
  display:flex;
  margin: 6px 0;
}
.profileDetails .profileDataList li span{
  width: 160px;
  color: #777;
  font-size: 18px;
}
.profileDetails .profileDataList li p{
  color: #111;
  font-size: 18px;
  font-family: 500;
}

.profileDetails button.addFriends img{
  margin-top: -2px;
  filter: brightness(10);
}
.profileDetails button.addFriends{
  background: #586cd8;
  border-radius: 4px;
  padding: 6px 6px 4px 12px;
  color: #fff;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 600;
  border: 1px solid transparent;
}

.profileDetails button.addFriends:hover img{
  filter: none;
}
.profileDetails button.addFriends:hover{
  background-color: transparent;
  border:1px solid #586cd8;
  color: #586cd8;
}
.profileDetails .favouriteTable,
.profileDetails table,
.profileDetails .phoneTable{
  box-shadow: none !important;
  margin-top: 10px;
  
}
.profileDetails .phoneTable tr{
  border-bottom: 0;
}
.profileDetails .phoneTable td{
  font-size: 16px;
  padding: 8px 6px;
  background: none;
  box-shadow: none;
}
.profileDetails .phoneTable td .phoneUser{
  height: 26px;
  max-width: fit-content;
}
.profileDetails button.cross img,
.profileDetails .phoneTable td button img{
  height: 26px;
  margin: 3px;
}
.profileDetails .favouriteTable table{
  border-collapse:separate;
  border-spacing:0 8px;
}
.profileDetails .favouriteTable tr{
  background: #ece8fe;
}
.profileDetails .favouriteTable td{
  font-size: 16px;
  color: #181a34;
  border: 0;
}
.profileDetails .favouriteTable .operatorImg img{
  height: 35px;
  margin-top: 6px;
}
.profileDetails .favouriteTable td .payNow{
  background: #586cd8;
  color: #fff;
  padding: 4px 12px;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 14px;
  white-space: nowrap;
  border: 1px solid #586cd8;
}

.profileDetails .favouriteTable td .payNow:hover{
  background: transparent;
  color: #586cd8;
  border: 1px solid #586cd8;
}

.myRupee{
  background: #ede9fe;
  padding: 20px;
  border-radius: 12px;
}

.myRupee .myRupeeText{
  text-align:center;
  font-weight:600;
}

.myRupee .rupeeText{
  margin: auto 15px;
  text-align: center;
  font-size: 22px;
  color:#181a34;
  font-weight: 800;
  white-space: nowrap;
}

.giftCardSet{
  margin-top: 30px;
}

.giftCardSet .swiperSet{
  text-align: center;
}

.giftCardSet .swiper-slide a{
  text-align: center;
  display: block;
  width: 100%;
  height: 100%;
}
.giftCardSet img{
  border-radius: 10px;
  vertical-align: bottom;
  width: 96%;
  height: 100%;
}

.refferalSet{
  margin: 0px auto;
}

.refferalSet .refferalImg img{
  width: 100%;
}

.refferalSet .refferalBox{
  background-color: #ede9fe;
  border-radius: 8px;
  text-align: center;
  vertical-align: middle;
  padding: 35px 10px 15px;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.refferalBox h5 small{
  font-size: 12px;
  font-weight: 500;
  display: block;
  color: #586cd8;
  margin: 15px 0 25px;
}
.refferalBox h5{
  width: 100%;
  font-size: 20px;
  font-weight: 600;
}
.refferalBox .refferalInput{
  display: flex;
  max-width: 70%;
  margin: auto;
}
.refferalBox .refferalInput input{
  height: 18px;
  text-align: center;
  background: #fff;
  border: 0;
  padding: 11px 10px;
  border-radius: 4px 0 0 4px;
}
.refferalBox .refferalInput fieldset:hover,
.refferalBox .refferalInput fieldset{
  border: 0;
}

.refferalBox .refferalInput button{
  padding: 10px 35px;
  color: #fff;
  text-align: center;
  height: 40px;
  background: #faba3f;
  text-transform: capitalize;
  white-space: nowrap;
  border-radius: 0 4px 4px 0;
}


.refferalBox .refferalInput button:hover{
background: #586cd8;
}
.refferalBox .socialIcon{
  display: flex;
  margin: 25px auto 30px;
  text-align: center;
  width: fit-content;
}
.refferalBox .socialIcon a img{
  height: 29px;
  width: 29px;
  margin: 5px 7px;
}


.modal{
  border: 0!important;
  border-radius: 10px;
  min-width: 800px;
  max-width: 100%;
}

.closeBtn{
  float: right;
  padding: 0!important;
  margin: 0!important;
  min-width: 24px!important;
}

.modalForm{
  margin-top: 25px;
}

.modalForm input{
  height: 30px;
  padding: 10px;
}

.modalForm .updateBtn{
  color: #fff;
  text-transform: capitalize;
  margin: 15px auto 0;
  width: 70%;
  font-size: 16px;
  background: #586cd8;
  padding: 8px 15px;
}

.phoneTable::-webkit-scrollbar{
  height: 3px!important;
  margin-top: 5px;
  border-radius: 6px;
}

@media only screen and (max-width: 1024px) {
  .profileDetails .phoneTable td{
    font-size: 14px;
    padding: 8px 4px;
  }
  
  .profileDataList .verified,
  .profileDataList .nonVerified{
    position: absolute;
    margin-right: 15px;
    width: 100px;
  }
  .profileDetails button .profileEdit{
    height: 22px;
  }
  .profileDetails .profileDataList li span{
    font-size: 14px;
  }
  .profileDetails .profileDataList li p{
    font-size: 14px;
  }
  .profileDetails .profileDataList li span{
    width: 120px;
  }
  .profileBox .sideMenu .menuList a .sideIcon img{
    height: 26px;
    width: 26px;
  }
  .profileBox .sideMenu .menuList a .menuTitle span{
    font-size: 16px;
  }
  .profileImgBox h4{
    font-size: 20px;
  }
  .profileImgBox h5{
    font-size: 12px;
  }
  .profileDetails h3{
    font-size: 24px;
  }
  .profileDetails button.addFriends{
    font-size: 12px;
  }
  .profileDetails button.cross img,
  .profileDetails .phoneTable td button img{
    height: 24px;
  }
}
@media only screen and (max-width: 919px) {
   
  .modal{
    min-width: 700px;
  }
  .profileDetails .phoneTable td .phoneUser{
    height: 28px ;    
  }
  .profileDetails .favouriteTable td .payNow{
    width: fit-content;
    margin-left: auto;    
  }

  .profileDetails .phoneTable td button{
    display: contents;    
  }

  .profileDetails .phoneTable td{
    min-width: 45px;
    font-size: 14px;
    line-height: 0;
    padding: 4px 8px;
    white-space: nowrap;
  }

  .profileDetails button.addFriends{
    padding: 4px 4px 4px 10px;
    font-size: 12px;
  }
  .profileDetails h3{
    font-size: 22px;
  }
  .profileBox .sideMenu .menuList a .menuTitle img{
    height: 25px;
    width: 25px;
  }

  .completeProfile .progressSet{
    width: 80px!important;
    height: 70px!important;
  }
  .profileBox .sideMenu .menuList a .menuTitle span{
    font-size: 14px;
  }
  .helpBox .chatImg{
    margin-right: 4px;
    height: 26px;
  }
  .profileDetails button.cross img,
  .profileDetails .phoneTable td button img,
  .profileDetails button .profileEdit{
    height: 20px;
  }
  .profileDetails .profileDataList li div{
    margin: 4px 0;
  }
  .profileImgBox h4{
    font-size: 20px;
  }
  .helpBox button{
    font-size: 16px;
    padding: 4px 14px;
  }

  .profileDetails .profileDataList li h3{
    font-size: 22px;
  }

  .profileDetails .profileDataList li span{
    font-size: 16px;
  }
  
  .profileDetails .profileDataList li p{
    font-size: 14px;
  }
  .helpBox{
    margin: 30px 15px 15px;
    padding: 10px 12px 15px;
  }
  .profileDetails .profileDataList li span{
    width: 130px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .profileBox{
    margin-bottom: 0!important;
  }
  .modal{
    min-width: 600px;
  }
  .profileBox .sideMenu .menuList a .sideIcon img{
    width: 24px;
    height: 24px;
  }

  .profileImgBox h5{
    font-size: 12px;
  }
  .profileImgBox h4{
    font-size: 16px;
  }
  .completeProfile .progressSet{
    width: 80px!important;
    height: 70px!important;
  }
  .profileDetails .favouriteTable td{
    min-width: 60px;    
    display: grid;
    padding: 10px 15px;
  }
  .profileBox .sideMenu .menuList a .sideIcon{
    min-width: 45px;
  }

}

@media only screen and (max-width: 600px) {
  
  .profileBox .sideMenu .menuList{
    margin: 10px -20px; 
  }
  .modalSubtext{
    width: 80%!important;
  }

  .refferalBox .refferalInput{
    max-width: 90%;
  }
  .profileBox .sideMenu .menuList a{
    border-radius: 4px 4px;
    border-bottom: 1px solid #efefef;
  }
  .profileImgMobile{
    display: flex;
    background: #fbf2c3;
    padding: 35px 30px 35px;
    margin: -60px -40px -60px;
    border-radius: 14px 14px 0 0;
  }
  .profileImgBox h4{
    font-size: 20px;
  }
  .sideMenu small{
    display: none;
  }
  .profileImgBox .members{
    margin:20px 25px 0;
    text-align: left;
  }
  .myRupee .myRupeeText{
   text-align: center; 
  }
  .myRupee .rupeeText{
    margin: auto 20px;
  }
  .rupeeBag div{
    margin: auto;
  }
  .refferalBox .refferalInput button{
    padding: 10px;
  }
  .modal{
    min-width: 500px;
    height: 100%;
  }
  .profileDetails .profileDataList li div{
    display: block;
  }
  .profileDataList .verified,
  .profileDataList .nonVerified{
    margin-top: -16px;
    text-align: right;
    margin-right: 0!important;
  }
  .profileDataList button{
    margin-top: 24px;
    position: absolute;
    right: 0;
  }
  .profileDetails h3{
    font-size: 18px;
  }
  .profileDetails{
    padding: 15px;
  }
  .profileDetails .actionBtn{
    display: block;
  }
}

@media only screen and (max-width: 414px) {
  .profileDetails .profileDataList li p,
  .profileDetails .profileDataList li span{
    font-size: 13px;
  }
  
  .profileDetails button.addFriends img{
    padding: 0;
    margin: 0;
  }
  .profileDetails button.addFriends{
    padding: 0;
    margin: 0;
    width: 26px;
    height: 26px;
  }
  .profileDetails button.addFriends span{
    display: none;
  }
  
  .modal{
    min-width: 320px;
    height: 100%;
  }
  .completeProfile h6{
    font-size: 16px;
  }
}

@media only screen and (max-width: 360px) {
  .modal{
    min-width: 300px;
    height: 100%;
  }
}