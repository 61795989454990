.testi-user{
   border-radius: 15px;
   width: 88%;
   padding: 5px 10px;
   display: flex;
   flex-direction: row;
   line-height: 24px;
   justify-content: flex-start;
   margin: 0px 20px 0px 10px;
   align-items: center;
   cursor: pointer;
   }
   
   .testi-user img{
     border-radius:100%;margin:0 15px 0 0;width:auto;
     padding:20px 10px;
   }
 .slider1 {  margin-top: 20px;}
.slider1 .slick-slide{ border-right:3px solid #f5f5f5!important;}
.slider1 .slick-current {border-right: 3px solid rgb(250, 175, 86)!important;}
.slick-current .testi-user{
   background: #FFFFFF;
   box-shadow: 3px 4px 9px 3px #c3c6e94d!important;
 
 }
.contentSlider h5
{
font-weight: 400!important;
font-size: 14px!important;
line-height: 20px!important;
display: flex!important;
align-items: center!important;
letter-spacing: 0.25px!important;
color: #162133!important;


}
.contentSlider .slick-prev, .contentSlider .slick-next
  {
	  top:auto;
	  bottom:-60px;
	  background: transparent;
	  width: 30px;
    height: 30px;
    background-repeat: no-repeat;
	opacity:0.5;
  }
  .contentSlider .slick-prev:before, .contentSlider .slick-next:before{
    content: "";
}
  .contentSlider .slick-prev,.slider .slick-prev:hover {
    background-image: url('../../../public/assets/images/Icons/left-icon.svg');
   
}

.contentSlider .slick-next:hover,
.contentSlider .slick-prev:hover {
	opacity:1;
}


.contentSlider .slick-next,.slider .slick-next:hover {
    background-image: url('../../../public/assets/images/Icons/right-icon.svg');
   
}



 @media(min-width:1420px){
 .slider1 .slick-list{
min-height:350px
 }
}
 @media(max-width:1023px){
  .slick-current .testi-user,.slider1 .slick-current{
    border-right: 0px solid!important;
	box-shadow:none!important;
	border-radius:0px
  }
  .testi-user{
    width: 100%;padding:20px 0;margin:5px 0;
	/*border-bottom: 1px solid #EAEDFE;*/
	border-radius:0px
  }
  .testi-user img{
	  padding:0;
	  margin:0 20px 0 0
  }
  .contentSlider .slick-prev {
    left: 45%;
}
.contentSlider .slick-next {
    right: 45%;
}
 }
 @media(max-width:767px){
  .slick-current .testi-user,.slider1 .slick-current{
    border-right: 0px solid!important;
	box-shadow:none!important;
	border-radius:0px
  }
  .testi-user{
    width: 100%;padding:20px 0;margin:5px 0;
	/*border-bottom: 1px solid #EAEDFE;*/
	border-radius:0px
  }
  .testi-user img{
	  padding:0;
	  margin:0 20px 0 0
  }
    .contentSlider h5{
	  font-weight: 300!important;
font-size: 12px!important;
line-height: 18px!important;
  }
    .contentSlider h3{
font-size: 18px;
line-height: 21px;
  }
  .contentSlider .slick-prev {
    left: 40%;
}
.contentSlider .slick-next {
    right: 40%;
}
 
 }