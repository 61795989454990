body{
    overflow-x: hidden;
}
.breadcrumb{
  padding: 15px 15px 25px;
}
.breadcrumb li a:hover{
  text-decoration: none;
}
.breadcrumb li p{
  font-weight: 600;
}
.breadcrumb li svg{ 
  color: #111;
}

@media only screen and (max-width: 600px) {
  .breadcrumb{
    display: none!important;
  }
}