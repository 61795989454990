.tabHeading {
    text-align: center;
    width: 200px;
    color: #000;
    cursor: pointer;
    margin-right: 7px!important;
    background: #fff;
    box-shadow: 0 5px 7px hsl(222deg 6% 59% / 15%)!important;
    border-radius: 6px 6px 0px 0px!important;
    display: inline-flex;
    min-height: 70px;
    align-items: center;
	opacity: 1!important;
	background:#fff!important;
	margin-bottom: 10px!important;
}
.Mui-selected.tabHeading {
    box-shadow: none!important;
}
.tabHeading img{
	height:25px; margin-right: 25px!important;
}
.tabs{
	justify-content:center;width:100%;display:flex;
}

.tabHeading:hover h2, .tabHeading:hover span {
    color: #162133;
    font-weight: 600;
}
.tabHeading:hover img{
	filter:brightness(0);
}


.tabHeading h2, .tabHeading span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: #91959E;
	margin: 0;
    text-transform: capitalize;
}

@media(max-width:1239px){
.tabHeading img {
  margin-right: 10px!important;
}
.tabHeading h2,.tabHeading span{
font-size: 14px;
line-height: 16px;
}
.tabHeading {
width: 160px;padding: 10px !important;
}
.mainBannerHeading{
  top: 20px;
}
.rightIcons img{
	margin:0 5px;
}
}

@media(max-width:1023px){
.tabHeading img {
  margin-right: 5px!important;
}
.tabHeading h2,.tabHeading span{
font-size: 12px;
line-height: 16px;
}
.tabHeading {
width: 140px;padding: 10px !important;box-shadow: none!important;
margin-bottom:0!important;
}
.mainBannerHeading{
  top: 20px;
}
.rightIcons img{
	margin:0 5px;
}
}

@media(max-width:767px){
.tabs{overflow-x:auto!important;
justify-content:inherit!important}

.tabHeading h2,.tabHeading span{
  justify-content: center;
  color: #5f5f5f;
  font-weight: 500!important;
font-size: 10px;
line-height: 14px;
}
.tabHeading img {
  width: 44px;
  height: 44px;
  border-radius: 13px;
  padding: 7px;
  margin:0px 0 8px 0!important;
  border: 0.6px solid #616789;
}
.tabHeading{
width: auto;
margin-right: 0 !important;
padding: 14px 5px!important;
box-shadow: none;
background:transparent;
margin-right:0;
display: flex !important;
flex-direction: column !important;
}
.more div{
	width:44px;
}
}