.box-shadow1{
background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
border-radius: 6px;
width: 100px;
height: 100px;
margin-right: 20px!important;
align-self: center;
display: flex;
justify-content: center;
cursor:pointer;
}

.box-shadow1 img{
	display:block;
	margin:auto;
}

.operator-heading{
	font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 36px;
letter-spacing: 0.0015em;
color: #162133;
}