.outerBox{
background: #F5F6FF;
box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
border-radius: 15px;
}
.innerBox{
display: flex;
align-items: center;
}
.innerBox h2{
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px;
letter-spacing: 0.5px;
color: #000000;
margin:0;
}
.outerBox p{
color:#91959E;
font-weight: 400;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.15px;
margin-bottom: 5px;
margin-top: 5px;
}

.outerBox p img{
	margin-right:10px;
	margin-top: 5px;
}

.outerBox span{
font-weight: 500;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.15px;
color: #162133;
}

.outerBox h3
{
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
cursor:pointer;
letter-spacing: 0.18px;
text-decoration-line: underline;
color: #2341D5;
margin:0;
}

@media(max-width:767px)
{
	.innerBox h2{
		margin-left: 5px;
		font-size: 12px;
		margin-bottom:10px;
	}
	.outerBox h3
{
		font-size: 12px;
}
.outerBox	p{
		margin-top:0px;
		margin-bottom:0px;
			font-size: 12px;
line-height: 12px;
margin-left: 5px;
	}
.outerBox	span{
		font-size: 12px;
line-height: 24px;
	}
	.outerBox{
		padding-top:0!important
	}
	.innerBox {
    margin-top: 15px;
}

}