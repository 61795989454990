.Outerbox{
  background: #FFFFFF;
border-radius: 15px;
padding:20px 40px;
}

.headingdiv{
  margin-bottom: 30px;
  text-align:center;
  background-color: #fff;
  padding-top:20px;
  border-bottom: 1px solid #EAEDFE;
}
.headingdiv .heading
{
font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 35px;
text-align: center;
text-transform: capitalize;
color: #162133;
}

.headingdiv .sub{
  
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
text-align: center;

color: #91959E;
margin-bottom: 30px;
}

.moduleTabs{
  padding: 10px 10px 30px 10px;
}

.moduleTabs .slick-active .user{
  background: #FFFFFF;
  box-shadow: 3px 4px 9px 3px #c3c6e94d!important;
border-radius: 15px!important;
color: #162133!important;
}


.moduleTabs h2{
text-transform: capitalize;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px;
letter-spacing: 0.15px;
color: #162133;
text-align: left;
}

.moduleTabs h5{
text-transform: capitalize;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */
text-align: left;
letter-spacing: 0.25px;
color: #9A98A2!important;
}

.moduleTabs .active, .moduleTabs .MuiTab-root.Mui-selected{
  background: #FFFFFF;
  box-shadow: 3px 4px 9px 3px #c3c6e94d!important;
border-radius: 15px!important;
color: #162133!important;
}

.user{
border-radius: 15px;
width: 424px;
padding: 20px;
display: flex;
flex-direction: row;
line-height: 24px;
justify-content: flex-start;
margin: 10px 30px 10px 10px;
align-items: center;
}

.user img{
  border-radius:100%;margin:0 20px 0 0;width:auto;

}

.outerDiv{
 margin-right: 40px;
  /*min-height: 335px;*/
}

.moduleTabs h3{
font-weight: 500;
font-size: 24px;
line-height: 24px;
/* identical to box height, or 100% */
text-align: left;
letter-spacing: 0.18px;

color: #162133;
}


.moduleTabs h5{
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
text-align: left;
/* or 143% */

display: flex;
align-items: center;
letter-spacing: 0.25px;

color: #162133;
}

.moduleTabs svg{
color:#F5BC5C;
margin:15px 0;
text-align: left;
}


@media(max-width:767px){
  .Outerbox{
  padding:0 0 40px 0;
  }
  .outerDiv{
  margin-right: 5px!important;

  border-right: none!important;}

  .heading {
    font-size: 20px!important;
    line-height: 20px!important;
	font-style: normal;
font-weight: 600!important;
margin-left:5px!important;
text-transform: capitalize;

  }
  .sub {
	  margin-left:5px;
    font-size: 12px;
    line-height: 0px;
	color:#91959E;
	margin-bottom:30px;

  }

  .moduleTabs{
    padding:25px 25px 0 25px;
	border-bottom: 1px solid #EAEDFE;
  }
   .moduleTabs h2{
	   font-size: 14px;
line-height: 16px;
   }
}