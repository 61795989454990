.container{
  width: 80%;
  margin: 40px auto;
}
.tabPanel{
  display: flex;
}
.tabHeading{
  background-color: white;
  padding: 12px 16px ;
  cursor: pointer;
  /* border-top-left-radius:10px;
  border-top-right-radius:10px; */
}

.tabHeading  p{
  width: 140px;
}
.tabContainer{
  height: 100%;
}
.innerTabPanel{
  display:flex;
}
.active{
  background-color: #ade6f6;
  color: #8ba0d4;
}

.activePanel{
  width: 100%;
  height: 280px;
  background-color: white;
  border-radius: 0 10px 10px 10px;
  padding:12px 24px;
  color: #8ba0d4;
}
.tabs{
	text-transform:lowercase;
	overflow:visible!important;
}
.tabs button{
	text-transform:Capitalize;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.15px;
margin-left:20px;
padding:0;
}
.tabs button h2{
	text-transform:Capitalize;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.15px;
}
.tabs img{
  width:35px;margin-top: 0;
}
.input{
border:1px solid #f5f5f5;

}

.moduleTabs{
background: #FFFFFF;
box-shadow: 0px 7px 15px rgba(237, 239, 255, 0.5);
border-radius: 7px 7px 0px 0px;
margin-top: -35px;
 margin-left: -45px;margin-right:-45px
}

.moduleTabs h1{
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 23px;
color: #162133;
margin-bottom: 10px;
}

.moduleTabs h4{
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
color: #91959E;
margin-bottom: 20px;
}
.moduleTabs h5{
  font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;
color: #162133;
margin-bottom: 20px;
}

.moduleTabs input{
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 21px;
color: #162133;
padding:45px 0 15px 12px;
height:auto!important;
background:transparent;

box-shadow: 0px 7px 25px rgba(255, 255, 255, 0.1);
border-radius: 10px;
}
.moduleTabs .MuiInputLabel-root{
font-size: 16px;
}

.selectbox,.selectbox:hover,.selectbox:focus{
	border: 1px solid #EAEDFE!important;
	border-radius: 10px!important;
	background:#fff!important;
	
}

.selectbox .MuiSelect-select{
	min-height:0;
}

.selectbox .MuiFilledInput-input:focus{
	background:#fff!important;
	padding:40px 0 10px 12px!important;
}


.MuiSelect-select{
font-weight: 500;
}

.moduleTabs button{
border-radius: 15px;
}

.adornment p {
  align-items: baseline!important;
  font-size:12px;
  color:#2341D5!important;
  cursor:pointer;
  position: relative;
    top: 15px;
}
.selectControl{
	position:relative;
}

.selectControl .adornment p{
	position:absolute;
	right:15px;
	top: 50px;
}
/*
.adornment p{
  font-size:12px;
  color:#2341D5;
  cursor:pointer;
  top: 15px;
  position: relative;
  }
  */
  .sellingPlanHeading{
    font-weight: 500!important;
    font-size: 16px!important;
    line-height: 19px!important;
    color: #162133!important;
  }
  .view{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400!important;
    font-size: 12px!important;
    line-height: 14px;
    text-align:right;
    float:right;
    color: #2341D5;
  }


 .box p{
    font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
margin: 0;
color: #162133;
  }

 .box .span{
    font-family: 'Roboto';
font-style: normal;
font-weight: 400!important;
font-size: 9px!important;
line-height: 12px;

color: #162133;
  }

  .buy{
    background: #2341D5;
border-radius: 6px!important;
font-weight: 600;
font-size: 10px;
line-height: 10px;
text-align: center;
color: #FFFFFF;
border: 0;
width: 60px;
height: 20px;
cursor:pointer;
  }


  .box{
  background: rgba(234, 237, 255, 80%);
box-shadow: 0px 7px 10px rgba(234, 237, 255, 0.3);
backdrop-filter: blur(20px);
display: flex;
justify-content: space-between;
align-items:center;
border-radius: 10px;
padding: 10px 15px;
  }
  
.MuiSelect-icon::-webkit-scrollbar{
	display:none;
}

 @media(max-width:1439px){
	  .box{
		padding: 8px 7px;
	  }
	  .moduleTabs{
	  margin-left: -45px;margin-right:-45px
	  }
  }
  @media(max-width:1023px){
	  .moduleTabs button{
		  padding:5px;
	  }
  }