body {
  font-family: "Roboto";
}

.payruplogo {
  max-width: 120px;
  margin-left: 20px;
  cursor:pointer;
}
.topheader a{
color:#91959E;text-decoration:none;height:18px;display: flex;cursor: pointer;
}
.topheader a:hover{
	color:#000;
}
.box {
  display: flex;
  box-shadow: 0px 0px 10px rgba(207, 207, 207, 0.3);
  border-radius: 6px;
  background-color: #fff;
  padding: 3px 12px;
  margin-left: 16px;
  height: 47px;
}


.box h5 {

font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 15px;
}

.box p {

font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 10px;
}
.notification img{
	display:block;
}
.box img {
  margin-right: 10px;
  display:block;
}
.search{
	border-right:1px solid #C4C4C4;
	padding: 0 8px;
  position: relative;
    top: 4px;
}
.mic{
	padding: 0 8px;
  position: relative;
    top: 4px;
}

.innerContainer {
  /* width: 750px;
  height: 80px;
    box-shadow: 0px 7px 50px rgba(228, 239, 248, 0.24);*/  

  border-radius: 6px 6px 0px 0px;
  border-radius: 10px;
}
.tabHeading {  
  text-align: center;
  width: 186px;
  color: #000;
  cursor: pointer;
  margin-right: 7px;
  background: #fff;
  box-shadow: 0px 5px 7px rgba(145, 149, 158, 0.15);
border-radius: 6px 6px 0px 0px;
  display: inline-flex;
  min-height: 70px;
  align-items: center;
}

.active {
  color: rgb(89 133 202);
}
.mobileHeading{
  font-weight: 700!important;
font-size: 16px!important;
line-height: 19px!important;
text-transform: capitalize;
color: #162133;
margin-top: 25px!important;
margin-bottom: 25px!important;
}
.mainBannerSection {
   position: relative!important;
   z-index: 1;
}

.mainBannerHeading {
  position: absolute;
  top: 50px;
  text-align: center;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
}
.mainBannerHeading h4,.mainBannerHeading h4 span{
font-style: normal;
font-weight: 600;
font-size: 32px;
line-height: 36px;
text-align: center;
color: #000000;
}

.mainBannerHeading p{

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #000000;
    margin-top: 10px;
}
.mainBannerHeading span{

font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
text-align: center;
letter-spacing: 0.15px;
color: #000000;
}

.tabHeading h2,.tabHeading span {

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;display: flex;
align-items: center;
letter-spacing: 0.15px;
color: #91959E;
}
.tabHeading:first-child{
  box-shadow:none;
}
.tabHeading:first-child h2,.tabHeading:first-child span {
/*font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.15px;
*/
color: #162133;
font-weight: 600;
}

.tabHeading img{
  margin-right: 15px;
  height: 25px;
}

.moreMenu{
	padding:0
}


@media(max-width:1023px){
.imageGrid img {
  margin-right: 5px;
}
.tabHeading h2,.tabHeading span{
font-size: 12px;
line-height: 16px;
}
.tabHeading {
width: 135px;
}
.mainBannerHeading{
  top: 20px;
}
.rightIcons img{
	margin:0 5px;width: 18px;
}
.innerContainer {
	margin:0 -30px;
}
}


@media(max-width:767px){
.imageGrid img {
  display: block;
    text-align: center;
    margin: auto;
}
 .innerContainer{
    box-shadow: 0px 8px 24px rgb(139 154 169 / 25%);
    background: #fff;
    flex-wrap: nowrap;
    display: flex;
    overflow-x: auto;
	align-items: baseline;
	margin-bottom: 50px;
	margin-left:0;
	margin-right:0;
  }
  .mainBannerHeading{
  top: 30px;
  left: 20px;
  transform: translateX(0);
}
  .mainBannerHeading h4{
  font-size: 24px;
    line-height: 26px;
    text-align: left;
}
.mainBannerHeading h4 span{
  text-align: left;
 font-size: 24px;
  display: block;
    text-align: left;
  }
.mainBannerHeading p{
  font-size: 16px;
  line-height: 16px;
  text-align: left;
}
.innerBox{
  flex-direction: column!important;
  width: 78px!important;
  margin-bottom: 15px;
margin-top: 15px;
}
.tabHeading h2,.tabHeading span{
  justify-content: center;
  color: #5f5f5f;
  font-weight: 500!important;
font-size: 10px;
line-height: 16px;
}
.tabHeading .imageGrid {
  margin-right: 0;
  width: 44px;
  height: 44px;
  border-radius: 13px;
  padding: 7px;
  margin-bottom:8px;
  border: 0.6px solid #616789;
}
.tabHeading{
width: auto;
padding: 0 5px;
box-shadow: none;
background:transparent;
margin-right:0;
}
.payruplogo {
  max-width: 100px;margin-left: 50px;
}

}