.heading h3 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}
.heading h4 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #91959e;
  margin-bottom: 20px;
}
.heading h5 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #162133;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.css-jpln7h-MuiTabs-scroller{
		overflow:visible!important;
}
.Tabss .MuiTabs-scroller,.Tabss .MuiTabs-fixed{
	overflow:visible!important;
	
}
.planTabs .MuiTabs-scroller.MuiTabs-fixed{
	overflow:visible!important;
}
.planTabs button {
  justify-content: flex-start;
  min-height: auto;
  margin: 15px 0;
  padding: 0px 15px!important;
  border-radius: 0px;
  border: 0;
  cursor:pointer;
}

.planTabs h6 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #91959e;
  margin-left: 6px;
  text-align:left;
}
.planTabs .active {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #162133;
 /* border-left: 3px solid #faaf56;*/
}
.planTabs button.Mui-selected h6 {
  color: #162133;
  font-weight: 500;
}
.planTabs button:hover h6 {
  color: #162133;
  font-weight: 500;
}
.planTabs button:hover img{
filter: brightness(0);
}

button.Mui-selected {
  border-left: 3px solid #faaf56;
}

.plansTable{
border-collapse: separate!important;
    border-spacing: 0 30px!important;

}
.tableDiv{
		height:500px;
	overflow-y:auto;
	padding-right: 15px;
}
.tableDiv::-webkit-scrollbar{width:5px;}
.tableDiv::-webkit-scrollbar-thumb{background: #91959E;border-radius: 2px;}


.plansTable th {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 19px;
  color: #182235 !important;
}
.plansTable thead tr {
  background-color: transparent;
    box-shadow: 0px 0px 0px rgba(196, 196, 196, 0.1);
}
.plansTable thead tr th{
 border:none;
 background: #fbfbfb;
}

.plansTable tr {
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0px 7px 10px rgba(196, 196, 196, 0.1);
   border-radius: 0px 15px  15px 0px!important;
     overflow:hidden;
	 position: relative;
}


.plansTable .amount {
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 800 !important;
  font-size: 20px !important;
  line-height: 23px !important;
  width: 100px;
  height: 80px;
  color: #162133;
  background: #eaecff;
  box-shadow: 0px 3px 10px rgba(234, 236, 255, 0.5);
  border-radius: 15px 0px 0px 15px !important;

}
.plansTable td {
  padding: 5px 16px !important;
  color: #162133;
  font-weight: 500 !important;
}
.plansTable td button {
  width: 100px;
  height: 45px;
  color: #fff;
  background: #2341d5;
  box-shadow: 0px 0px 10px rgba(234, 236, 255, 0.7);
  border-radius: 10px!important;
  margin:10px 0!important;
}
.plansTable .extra {
  border: none;
  position: absolute;
  background: #eaecff;
  border-radius: 0px 7px 0px 0px;
  padding: 0px 15px;
  color: #758089 !important;
  font-weight: 400 !important;
}

@media(max-width:1279px){
	.planTabs h6 {
 
  font-size: 14px;

}
}