
.modal:focus{
  outline: none;
}
.modal{
  border: 0!important;
  min-width: 841px;
  background: linear-gradient(230deg, #F9FCFF 57.92%, #bdb3f4 109.21%);
  box-shadow: 0px 7px 50px rgba(145, 149, 158, 0.5)!important;
  border-radius: 15px;
  outline: none;
}

.closeBtn{
  float: right;
  padding: 0!important;
  margin: 0!important;
  min-width: 24px!important;
}

.modal .signinImg{
  max-height: 300px;
}

.modalForm{
  margin-top: 25px;
  padding: 0 20px;
}

.modalForm .title{
  display: block;
  width: 100%;
  margin: 20px 0 30px;
  font-weight: 500;
  font-size: 24px;
  color: #000;
}

.modalForm label{
  color: #586cd8!important;
}

.modalForm .label2{
  font-size:14px;
  color:#111;
  width:80%;
  font-weight: 500;
  margin-bottom: 25px;
}
.modalForm input{
  height: 30px;
  padding: 6px;
  width: 100%;
  display: block;
  border-color: #586cd8;
}

.modal .terms label{
  margin-right: 0;
  padding-right: 0;
}
.modal .terms{
  display: block;
  margin: 10px 0 25px;
  font-size: 14px;
  font-weight: 500;
}
.modal .terms span{
  font-size: 14px;
  font-weight: 500;
}
.modal .terms a{
  text-decoration: none;
  color: #586cd8;
}
.modalForm .updateBtn{
  color: #fff;
  text-transform: capitalize;
  margin: 20px 0;
  width: 80%;
  font-size: 16px;
  background: #586cd8;
  padding: 8px 15px;
  max-height: 40px;
}

.modalForm .otpSet{
  display: flex;
  justify-content: space-between;
  margin: 30px 0 10px;
  width:80%;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.modalForm .sloader{
  margin:0 4px 0 8px;
  transform: rotate(360deg);
  transition: transform 0.3s infinite;
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.modal h6.hassle{
  display: block;
  text-align: right;
  color: #222;
  margin: 50px 0 15px;
  font-size: 14px;
}

.modal .modalForm .later{
  width: 80%;
  display: block;
  margin: 10px 0 25px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
}

.modal .modalForm .later a{
  text-decoration: none;
  color: #586cd8;
  font-size: 14px;
}



@media only screen and (max-width: 919px) {
.modal{
    min-width: 700px;
  }
}

@media only screen and (max-width: 768px) {

  .modal{
    min-width: 600px;
  }

}
@media only screen and (max-width: 600px) {

  .modal{
    min-width: 100%;
    height: 100%;
    border-radius: 0;
    max-width: 100%;
  }
  .modalForm .updateBtn{
    margin-top: 10px;
  }
  .modalForm .inputRes,
  .modalForm .updateBtn,
  .modalForm .label2,
  .modalForm .otpSet,
  .modal .modalForm .later{
    width: 100%!important;
  }
  .modal h6.hassle{
    text-align: center;
    margin-top: 20px;
  }
}
@media only screen and (max-width: 414px) {

  .modalForm .otpSet{
    display: block;
  }
  .modal{
    min-width: 320px;
  }
}

@media only screen and (max-width: 360px) {
  .modal{
    min-width: 300px;
  }
}