.headingdiv{background: #FDEAC6;
border-radius: 12px 12px 0px 0px;
padding: 15px 10px;
    margin-bottom: -5px;
}
.heading {
font-weight: 600!important;
font-size: 16px!important;
line-height: 19px!important;
color: #162133;
}
.heading h4 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #91959e;
  margin-bottom: 20px;
}
.Outerbox
{
background: #FFFFFF;
border: 1px solid #FFFFFF;
box-shadow: 0px 8px 24px rgba(139, 154, 169, 0.25);
border-radius: 12px;
}
.tabs{
	border-bottom: 1px solid;
    border-color: #EAEDFE;
}
.tabs button{
text-transform: capitalize;
}
.heading h5 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #162133;
  margin-bottom: 20px;
  text-transform: capitalize;
}

@media(max-width:767px){
.imageGrid img {
  display: block;
    text-align: center;
    margin: auto;
}
 .innerContainer{
    flex-wrap: nowrap;
    display: flex;
    overflow-x: auto;
	align-items: baseline;
justify-content: center;
  }

.innerBox{
  flex-direction: column!important;
  width: 78px!important;
  margin-bottom: 15px;
margin-top: 15px;
text-align: center;
}
.tabHeading h4,.tabHeading span{
  justify-content: center;
  color: #5f5f5f;
  font-weight: 500!important;
font-size: 10px;
line-height: 2px;
}
.tabHeading .imageGrid {
  margin-right: 0;
  width: 44px;
  height: 44px;
  border-radius: 13px;
  padding: 7px;
  margin-bottom:20px;
  border: 0.6px solid #616789;
}


}