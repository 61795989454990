.headingdiv{background: #FDEAC6;
border-radius: 12px 12px 0px 0px;
padding: 15px 10px;
    margin-bottom: -5px;
}
.heading {
font-weight: 600!important;
font-size: 16px!important;
line-height: 19px!important;
color: #162133;
}
.heading h4 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #91959e;
  margin-bottom: 20px;
}
.Outerbox
{
background: #FFFFFF;
border: 1px solid #FFFFFF;
box-shadow: 0px 8px 24px rgba(139, 154, 169, 0.25);
border-radius: 12px;
padding: 15px 20px;
}

.FormElements .MuiInputBase-formControl
{
	border: 0.5px solid #C9CEEA;
}


.FormElements input,.FormElements select{
background: #FFFFFF;
box-shadow: 0px 7px 25px rgba(255, 255, 255, 0.1);
border-radius: 6px;
padding: 18px 14px;
}

.adornment p{

  font-size:10px;
  color:#2341D5!important;
  cursor:pointer;
}

.hr{
margin:25px 0;
opacity: 0.4;
}

.recent h4{
font-weight: 600;
font-size: 16px;
line-height: 19px;
text-transform: capitalize;
margin-bottom:20px;
color: #162133;
}

.recent h5{
font-weight: 400;
font-size: 12px;
line-height: 14px;
color: #34373E;
}

.recent h6{
font-weight: 400;
font-size: 12px;
line-height: 14px;
/* identical to box height */


color: #91959E;
}

.recentList{
	padding:10px 0;
	border-bottom:1px solid #EAEDFE;
}
.recentList:last-child{
	border-bottom:0px solid #EAEDFE;
}
.recentList button{
background: #FFFFFF;
border: 0.5px solid #2442D5;
border-radius: 5px;
color: #162133;
float:right;
}

.recentList button:hover{
color: #FFFFFF;
background:#2442D5;

}