.box{
    margin: 25px auto 0;
    text-align: center;
}

.box ul li{
    padding: 4px;
}
.box ul li button{
    font-size: 12px;
    width: 28px;
    text-align: center;
    height: 28px;
    line-height: 28px;
    font-weight: 600;
    background-color: rgba(0, 0, 0, 0.08);
}

.box ul li button:hover{
    background-color: #586cd8;
    color: #fff;
    box-shadow: 0px 2px 4px -1px #1f1e1e;
}

.box ul li button svg{
    font-size: 25px;
}
