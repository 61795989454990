.box{
   border-radius: 15px;
   color:#fff;
   padding: 0 40px;
   display: flex;
   justify-content: space-between;
   align-items:center;
   margin:1rem 0;
}

.heading{
	font-size:35px;
	font-weight:600;
}

.sub{
		font-size:30px;
		margin-bottom:0;
}

.button{
	margin-top:30px;
width: 241px;
height: 60px;
background: #FFFFFF!important;
box-shadow: 0px 7px 50px rgba(104, 164, 227, 0.1);
border-radius: 15px!important;
box-shadow: 0px 7px 50px rgba(104, 164, 227, 0.1);
border-radius: 15px;
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 25px!important;
line-height: 29px;
display: flex;
align-items: center;
text-transform:capitalize!important;
color: #2341D5!important;
}
	
@media(min-width:768px){
   .box{
      justify-content: space-evenly;
   }
}

@media(max-width:1200px){
.box{
   margin:1rem 0;
}
}

@media(max-width:767px){
   .headingdiv .heading {
      font-size: 14px!important;
      line-height: 18px;
      text-align: left;
        font-weight: 500;
    }
    .headingdiv .sub {
      font-size: 12px;
    line-height: 26px;
    text-align: left;
    font-weight: 500;
    }
    .box{
      padding: 0px 20px;
      margin:1rem 0;
    }
   .heading{
      font-size:25px!important;
   }
   .sub{
		font-size:18px;
   }
   .button{
      width: 105px;
      height: 30px;
      font-size: 14px!important;
      margin: 15px 0!important;
      border-radius:8px!important;
  }
}