.slick-slide {
   width: 100%;
text-align: center;
  box-sizing: border-box;

}

.slick-slide img {
  margin: 0 auto;
  width: 100%;
}

.slider .slick-next,
.slider .slick-prev {
  width: 40px;
  height: 40px;
  z-index: 9;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
    border-radius: 100%;
	 background-repeat: no-repeat;
    background-position: center;
}

.slider .slick-prev,.slider .slick-prev:hover {
    background-image: url('../../../public/assets/images/Icons/left-icon.svg');
   
}
.slider .slick-next,.slider .slick-next:hover {
    background-image: url('../../../public/assets/images/Icons/right-icon.svg');
   
}
.slider .slick-prev:before, .slider .slick-next:before{
    content: "";
}

.boxInner{
	background: #F5F6FF;
    box-shadow: 0px 1px 4px rgb(237 239 255 / 50%);
    border-radius: 30px;
    padding: 10px;
    display: flex!important;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.box{

	padding:0 10px;
}

.box img{
	width:auto;
	display: inline-block;
	margin:0;
	
}

.box h5{
	margin:0;
	display: inline-block;margin-left:10px;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

letter-spacing: 0.25px;

color: #162133;
}

.slider{
	padding:20px 60px;
	margin-bottom:30px;
}

.slider .slick-prev {
    left: -5%;
}

.slider .slick-next {
    right: -5%;
}
@media(max-width:1023px){
.slider {
  padding: 20px 20px;
}
}
@media(max-width:767px){
    .slider{
        padding:20px 10px;
        display:none
    }
    .slider .slick-prev {
        left: -9%;
    }
    
    .slider .slick-next {
        right: -9%;
    }
    .main-banner .slick-slide img{
        height: 200px;
    }
}